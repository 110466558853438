import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '@core/services/http.service';
import { TYPE_ENDPOINT } from '@core/tokens/type-endpoint.token';
import { HvacSystemsModel } from '@core/models/hvac-systems.model';

@Injectable({ providedIn: 'root' })
export class TypesService {
  constructor(
    private readonly httpService: HttpService,
    @Inject(TYPE_ENDPOINT) private readonly typesEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  getConstructionTypes() {
    return this.http
      .get<string[]>(`${this.typesEndpoint}/construction_types`)
      .pipe(this.httpService.trackError());
  }

  getAssetFunctions() {
    return this.http
      .get<string[]>(`${this.typesEndpoint}/asset_functions`)
      .pipe(this.httpService.trackError());
  }

  getHvacSystems() {
    return this.http
      .get<HvacSystemsModel>(`${this.typesEndpoint}/hvac_system_types`)
      .pipe(this.httpService.trackError());
  }
}
