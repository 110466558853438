import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { HttpDataState } from '@core/types/http-data-state';
import { TypesService } from '@core/services/types.service';
import { HvacSystemsModel } from '@core/models/hvac-systems.model';
import { switchMap } from 'rxjs';

interface TypesStoreState {
  constructionTypes: HttpDataState<string[]>;
  assetFunctions: HttpDataState<string[]>;
  hvacSystems: HttpDataState<HvacSystemsModel>;
}

@Injectable({ providedIn: 'root' })
export class TypesStore extends ComponentStore<TypesStoreState> {
  readonly constructionTypes$ = this.select(
    ({ constructionTypes }) => constructionTypes,
  );

  readonly assetFunctions$ = this.select(
    ({ assetFunctions }) => assetFunctions,
  );

  readonly hvacSystems$ = this.select(({ hvacSystems }) => hvacSystems);

  constructor(private readonly typesService: TypesService) {
    super({
      constructionTypes: { status: 'idle' },
      assetFunctions: { status: 'idle' },
      hvacSystems: { status: 'idle' },
    });
  }

  readonly loadConstructionTypes = this.effect((event$) =>
    event$.pipe(
      switchMap(() => {
        this.patchState((state) => ({
          constructionTypes: { ...state.constructionTypes, status: 'loading' },
        }));
        return this.typesService.getConstructionTypes();
      }),
      tapResponse(
        (constructionTypes) => {
          this.patchState({
            constructionTypes: {
              status: 'success',
              data: constructionTypes,
              cachedAt: new Date(Date.now()),
            },
          });
        },
        (error: HttpErrorResponse) => {
          this.patchState((state) => ({
            constructionTypes: {
              ...state.constructionTypes,
              status: 'error',
              error: error,
            },
          }));
        },
      ),
    ),
  );

  readonly loadAssetFunctions = this.effect((event$) =>
    event$.pipe(
      switchMap(() => {
        this.patchState((state) => ({
          assetFunctions: { ...state.assetFunctions, status: 'loading' },
        }));
        return this.typesService.getAssetFunctions();
      }),
      tapResponse(
        (constructionTypes) => {
          this.patchState({
            assetFunctions: {
              status: 'success',
              data: constructionTypes,
              cachedAt: new Date(Date.now()),
            },
          });
        },
        (error: HttpErrorResponse) => {
          this.patchState((state) => ({
            assetFunctions: {
              ...state.assetFunctions,
              status: 'error',
              error: error,
            },
          }));
        },
      ),
    ),
  );

  readonly loadHvacSystems = this.effect((event$) =>
    event$.pipe(
      switchMap(() => {
        this.patchState((state) => ({
          hvacSystems: { ...state.hvacSystems, status: 'loading' },
        }));
        return this.typesService.getHvacSystems();
      }),
      tapResponse(
        (hvacSystems) => {
          this.patchState({
            hvacSystems: {
              status: 'success',
              data: hvacSystems,
              cachedAt: new Date(Date.now()),
            },
          });
        },

        (error: HttpErrorResponse) => {
          this.patchState((state) => ({
            hvacSystems: {
              ...state.hvacSystems,
              status: 'error',
              error: error,
            },
          }));
        },
      ),
    ),
  );
}
